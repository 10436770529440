.footer-main-container {
  margin: auto;
  font-family: "Short Stack", cursive;
  background-color: #fdf7ef;
  position: relative;
  z-index: 10;
  overflow: hidden;
  @media screen and (max-width: 1000px) {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .footer-container {
    height: 300px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
    align-items: center;
    position: relative;
    margin: auto;
    padding: 0 2rem;
    color: #fbf6ed;
    font-family: "Spectral", serif;
    @media screen and (max-width: 1000px) {
      margin: 0;
      width: 100%;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);

      height: 250px;
    }
    @media screen and (max-width: 450px) {
      height: 30vh;
    }
    .flexed-container {
      max-width: 1330px;
    }
    .footer-logo-container {
      // flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 10;
      flex-direction: column;
      text-transform: uppercase;
      img {
        height: 80px;
        opacity: 0.9;
      }
      p {
        margin: 0.3rem 0;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
    .black-bg {
      position: absolute;
      z-index: 9;
      left: 0;
      height: 115%;
      width: 100%;
    }
    .footer-location {
      flex: 1;
      // width: 33%;
      margin: auto;
      text-align: center;
      position: relative;
      z-index: 10;
      font-size: 1.2rem;
      @media screen and (max-width: 1000px) {
        width: 50%;
        font-size: 0.6rem;
        display: flex;
        // align-items: center;
        flex-direction: column;
      }
      @media screen and (max-width: 850px) {
        width: 60%;
      }
      @media screen and (max-width: 650px) {
        width: 70%;
      }
      @media screen and (max-width: 450px) {
        width: 80%;
      }
      .location-title {
        font-size: 1.2rem;
        margin: 0.5rem 0;
        @media screen and (max-width: 1000px) {
          font-size: 0.8rem;
        }
        @media screen and (max-width: 850px) {
          font-size: 0.6rem;
        }
      }
      .location-address {
        font-size: 0.9rem;
        margin-bottom: 0.5rem;
        text-underline-offset: 3px;

        a {
          color: #fdf7ef;
        }
        @media screen and (max-width: 1000px) {
          font-size: 0.6rem;
        }
        @media screen and (max-width: 850px) {
          font-size: 0.5rem;
        }
      }
      .location-phone {
        margin-bottom: 0.5rem;
        font-size: 0.9rem;

        @media screen and (max-width: 1000px) {
          font-size: 0.6rem;
        }
        @media screen and (max-width: 850px) {
          font-size: 0.5rem;
        }
      }
      .location-email {
        margin-bottom: 0.5rem;
        font-size: 0.9rem;
        text-underline-offset: 3px;
        a {
          color: #fdf7ef;
        }
        @media screen and (max-width: 1000px) {
          font-size: 0.6rem;
        }
        @media screen and (max-width: 850px) {
          font-size: 0.5rem;
        }
      }
    }
    .footer-hours {
      font-size: 0.8rem;
      // width: 33%;
      position: relative;
      flex: 1;
      z-index: 10;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 1200px) {
        font-size: 0.6rem;
      }
      @media screen and (max-width: 1000px) {
        // width: 50%;
        font-size: 0.6rem;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      @media screen and (max-width: 850px) {
        font-size: 0.6rem;
      }

      @media screen and (max-width: 550px) {
        font-size: 0.5rem;
      }
      @media screen and (max-width: 450px) {
        font-size: 0.4rem;
      }
      @media screen and (max-width: 375px) {
        font-size: 0.3rem;
      }

      .hours-title {
        text-align: center;
        width: 100%;
      }
      .hours {
        max-width: 260px;
        .hours-list {
          list-style: none;
          .day-time {
            padding: 0.2rem;
            display: flex;
            justify-content: space-between;
            .time {
              text-align: right;
              margin-left: 1rem;
            }
          }
          @media screen and (max-width: 1200px) {
            padding: 0;
          }
        }
      }
    }
  }
  .flower-container {
    @media screen and (max-width: 1000px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .flower1 {
      position: absolute;
      right: 2%;
      top: 50%;
      transform: translateY(-50%);
      height: 200px;
      @media screen and (max-width: 1000px) {
        display: none;
      }
    }
  }
}

.about {
  position: relative;
  z-index: 10;
  background-color: #fbf6ed;
  .about-container {
    height: 1100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70%;
    margin: auto;
    font-family: "Spectral", serif;
    font-size: 1.1rem;
    text-align: center;
    position: relative;
    z-index: 10;

    .about-header {
      font-size: 2rem;
      margin-bottom: 2rem;
      @media screen and (max-width: 1200px) {
        font-size: 1.8rem;
        margin-bottom: 2rem;
      }
      @media screen and (max-width: 768px) {
        font-size: 1.6rem;
        margin-bottom: 2rem;
      }
      @media screen and (max-width: 500px) {
        font-size: 1.4rem;
        margin-bottom: 2rem;
      }
      @media screen and (max-width: 350px) {
        font-size: 1.2rem;
        margin-bottom: 2rem;
      }
    }
    .about-text {
      p {
        font-size: 1.3;
        line-height: 3;
      }
      @media screen and (max-width: 768px) {
        font-size: 1.1rem;
        margin-bottom: 2rem;
      }
      @media screen and (max-width: 500px) {
        font-size: 0.9rem;
        margin-bottom: 2rem;
      }
      @media screen and (max-width: 350px) {
        font-size: 0.7rem;
        margin-bottom: 2rem;
      }
    }
    .floating-img-about {
      .flower1 {
        height: 300px;
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translateX(-50%);
        @media screen and (max-width: 1300px) {
          height: 180px;
        }
        @media screen and (max-width: 768px) {
          top: -2rem;
        }
        @media screen and (max-width: 500px) {
          height: 120px;
        }
      }
      .flower2 {
        height: 300px;
        position: absolute;
        bottom: 60px;
        left: 50%;
        transform: translateX(-50%);
        @media screen and (max-width: 1300px) {
          height: 180px;
          bottom: 60px;
        }
        @media screen and (max-width: 768px) {
          bottom: 40px;
        }
        @media screen and (max-width: 500px) {
          height: 120px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      height: 950px;
    }
  }
}

@import url("https://fonts.googleapis.com/css2?family=Short+Stack&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Spectral:wght@300;400;500;600&display=swap");

html {
  scroll-behavior: smooth;
  overscroll-behavior: none;
  // overscroll-behavior-y: none;
}
:root {
  scrollbar-width: none;
}
::-webkit-scrollbar {
  width: 0;
  height: 8px;
}

body {
  overscroll-behavior-y: none;
  box-sizing: border-box;
  background-color: #fbf6ed;
  margin: 0;
  padding: 0;
}

.header {
  position: fixed;
  margin: 0;
  z-index: 10000;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fbf6ed;
    margin: auto;
    position: relative;
    z-index: 1000;
    min-height: 50px;
    @media screen and (max-width: 1100px) {
      min-height: 40px;
    }
    .logo-container {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-size: 1rem;
      padding: 0px 2rem;
      position: relative;
      z-index: 999999;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Spectral", serif;

      @media screen and (max-width: 768px) {
        align-items: center;
        padding: 0px 1rem;
        font-size: 0.7rem;
      }
    }
    .list-items-container {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      .menu-list {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        gap: 1.5rem;
        font-size: 0.9rem;
        width: 100%;
        font-family: "Spectral", serif;

        .list-item {
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
          a {
            color: black;
            text-decoration: none;
          }
        }
      }
      @media screen and (max-width: 1100px) {
        display: none;
      }
    }

    .menu-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 1rem;
      @media screen and (min-width: 1100px) {
        display: none;
      }
    }
    .flower {
      .logo-img {
        height: 40px;
        width: 40px;
        margin: 0 1rem;
        padding: 1rem 1rem 1rem 0;
      }
      @media screen and (max-width: 1100px) {
        display: none;
      }
    }
  }
  .mobile-list-items-container {
    position: absolute;
    top: 0;
    left: 0;
    animation: slidein 1s;
    background-color: #fbf6ed;
    height: 100vh;
    animation: slidein 1s;
    z-index: 999;
    width: 100%;
    .mobile-menu-list {
      height: 100%;
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      font-family: "Spectral", serif;
      height: 100%;
      .mobile-list-item {
        font-size: 2rem;
        margin-top: 1rem;

        padding: 2rem;
        a {
          text-decoration: none;
          color: black;
        }
      }
    }
    &.close {
      animation: slideOut 1s;
      display: none;
    }
  }
}

@keyframes slidein {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(-0%);
  }
}
@keyframes slideOut {
  from {
    transform: translateY(0%);
  }

  to {
    transform: translateY(-100%);
  }
}

.menu-container {
  position: relative;
  z-index: 10;
  background-color: #fbf6ed;
  font-family: "Spectral", serif;
  padding: 0 0 5rem 0;
  .menu-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 0;
    font-family: "Spectral", serif;
    position: relative;
    z-index: 5;

    .menu-title {
      text-transform: uppercase;
      font-size: 2rem;
      padding: 2rem;
      margin-top: 3rem;
      @media screen and (max-width: 768px) {
        font-size: 1.8rem;
      }
      @media screen and (max-width: 500px) {
        font-size: 1.4rem;
      }
      @media screen and (max-width: 350px) {
        font-size: 1rem;
      }
    }
    .menu-categories {
      .menu-categories-list {
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        font-size: 1rem;
        margin: 0;
        padding: 0;

        @media screen and (max-width: 768px) {
        }
        @media screen and (max-width: 500px) {
          font-size: 0.9rem;
        }
        @media screen and (max-width: 350px) {
          font-size: 0.8rem;
        }
        .menu-categories-list-item {
          margin: 0 1.5rem;
          border: 3px solid black;
          padding: 0.3rem 0.8rem;
          border-radius: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          &.active {
            background-color: #000;
            color: #fbf9f6;
          }
          &:hover {
            background-color: #000;
            color: #fbf9f6;
            cursor: pointer;
          }
          @media screen and (max-width: 768px) {
            margin: 0;
            z-index: 10;
          }
        }

        @media screen and (max-width: 768px) {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-template-rows: repeat(2, 1fr);
          grid-gap: 10px;
          padding: 0;
        }
      }
    }
  }
  .floating-img {
    .flower-img {
      position: absolute;
      right: 1%;
      top: 100px;
      height: 300px;
      @media screen and (max-width: 1200px) {
        height: 250px;
      }
      @media screen and (max-width: 768px) {
        height: 250px;
      }
      @media screen and (max-width: 500px) {
        right: 1%;
        top: 30px;
        height: 180px;
      }
    }
    .flower-img2 {
      position: absolute;
      left: 0;
      top: 650px;
      height: 300px;
      @media screen and (max-width: 1200px) {
        height: 200px;
      }
      @media screen and (max-width: 768px) {
        height: 180px;
        top: 50%;
      }
      @media screen and (max-width: 500px) {
        height: 150px;
        left: -5%;
        z-index: 10;
        top: 50%;
      }
      @media screen and (max-width: 350px) {
        height: 100px;
        left: -5%;
        z-index: 10;
        top: 50%;
      }
    }
  }
  .menu-container-menu {
    font-family: "Spectral", serif;
    display: grid;
    grid-template-columns: repeat(2, 500px);
    grid-template-rows: auto 1fr;
    justify-content: center;
    width: 60%;
    margin: auto;
    text-align: center;
    height: 100%;
    position: relative;
    @media screen and (max-width: 1300px) {
      grid-template-columns: repeat(1, 500px);
    }
    .menu-col {
      .menu-section {
        margin-bottom: 1.6rem;
        .menu-section-title {
          margin-bottom: 1.6rem;
          font-size: 1.7rem;
          text-decoration: underline;
          text-underline-offset: 10px;
          text-decoration-thickness: 1px;
          @media screen and (max-width: 768px) {
            font-size: 1.5;
          }
          @media screen and (max-width: 500px) {
            font-size: 1.3rem;
          }
          @media screen and (max-width: 350px) {
            font-size: 1rem;
          }
        }

        .menu-item {
          width: 80%;
          margin: 1rem auto;
          @media screen and (max-width: 768px) {
            width: 100%;
          }
          .menu-item-heading {
            font-size: 1.4rem;
            margin-top: 1rem;
            @media screen and (max-width: 768px) {
              font-size: 1.3rem;
            }
            @media screen and (max-width: 500px) {
              font-size: 1.2rem;
            }
            @media screen and (max-width: 350px) {
              font-size: 1.1rem;
            }
            .menu-item-dietery {
              font-size: 0.6rem;
              margin-left: 0.5rem;
              margin-bottom: 0.5rem;
            }
          }
          .menu-item-description {
            font-size: 0.9rem;
            @media screen and (max-width: 768px) {
              font-size: 0.8rem;
              width: 80%;
              margin: auto;
            }
            @media screen and (max-width: 500px) {
              font-size: 0.7rem;
            }
            @media screen and (max-width: 350px) {
              font-size: 0.6rem;
            }
          }

          .menu-item-addon {
            font-size: 0.8rem;
            @media screen and (max-width: 768px) {
              font-size: 0.7rem;
            }
            @media screen and (max-width: 500px) {
              font-size: 0.6rem;
            }
            @media screen and (max-width: 350px) {
              font-size: 0.5rem;
            }
          }
          .menu-item-price {
            @media screen and (max-width: 768px) {
              font-size: 0.7rem;
            }
          }
        }
        .menu-item.long {
          width: 75%;
          .menu-col {
            width: 50%;
            margin: auto;
          }
        }
      }
    }
    &.long {
      grid-template-columns: repeat(1, 1fr);
      .menu-item {
        .menu-item-description {
          width: 50%;
          margin: 0.3rem auto;
        }
      }
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .menu-container.fade {
    animation: fadeIn 2s;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

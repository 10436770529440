.front-page-container {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  margin: auto;
  position: relative;
  z-index: 10;
  background-color: #fbf9f6;

  .announcement-banner {
    position: absolute;
    top: 120px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    text-transform: uppercase;
    color: black;
    font-size: 1.8rem;
    font-weight: 500;
    font-family: "Spectral", serif;
    // width: 100%;
    padding: 0 5rem;
    text-align: center;
    background-color: #fbf6ed;
    border-radius: 5px;

    @media screen and (max-width: 1200px) {
      top: 100px;
      font-size: 1.3rem;
    }
    @media screen and (max-width: 950px) {
      font-size: 1rem;
    }
    @media screen and (max-width: 850px) {
      font-size: 0.9rem;
      padding: 0 2rem;
    }
    @media screen and (max-width: 580px) {
      top: 80px;
      font-size: 0.8rem;
      text-align: center;
      padding: 0 1rem;
    }
    @media screen and (max-width: 460px) {
      // top: 60px;
      font-size: 1rem;
      text-align: center;
      padding: 0 1rem;
    }
  }

  .carousel {
    position: absolute;
    z-index: 25;
    right: 1%;
    bottom: 1%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.7rem;
  }

  .carousel-btn {
    border: none;
    background: transparent;
    background-color: rgb(149, 149, 149);
    filter: grayscale(50%);
    border-radius: 50%;
    height: 8px;
    width: 8px;
    padding: 0;
    cursor: pointer;

    &:hover {
      background-color: rgb(222, 222, 222);
    }
    @media screen and (max-width: 768px) {
      height: 6px;
      width: 6px;
    }
  }

  .carousel-btn.highlight {
    background-color: rgb(255, 255, 255);
  }

  .play-pause-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;

    .pause {
      width: 25px;
      height: 25px;
    }

    img {
      height: 25px;
    }
    &:hover {
      cursor: pointer;
      transform: scale(1.2);
      transition: 0.2s ease;
    }
  }

  .front-page-img-container {
    position: fixed;
    height: 100%;
    width: 100%;
    @media screen and (max-width: 768px) {
    }
    img {
      filter: brightness(70%);
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    .under {
      position: absolute;
      display: flex;
      z-index: 9;
      animation: fadeIn 0.3s ease-out alternate, zoomIn 20s infinite alternate;
    }
    .none {
      display: none;
    }
    .slide.active {
      overflow: hidden;
      display: flex;
      animation: fadeOut 0.15s ease-out 6.5s alternate;
      z-index: 10;
      object-fit: cover;
      position: absolute;
      z-index: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      box-shadow: 0px 0px 6px -3px #000;
      border-radius: 2px;
      filter: brightness(70%);
    }
  }
  .logo-name {
    font-size: 3rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #fbf9f6;
    text-transform: uppercase;
    letter-spacing: 0px;
    word-spacing: 0.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Spectral", serif;
    text-shadow: 0 0 50px rgb(36, 36, 36);
    @media screen and (max-width: 1100px) {
      font-size: 3rem;
    }
    @media screen and (max-width: 850px) {
      font-size: 2.2rem;
    }
    @media screen and (max-width: 650px) {
      font-size: 1.8rem;
    }
    @media screen and (max-width: 520px) {
      font-size: 1.2rem;
      text-align: center;
    }
    .spacing {
      padding: 0 0.5rem;
      @media screen and (max-width: 1100px) {
        padding: 0 0.3rem;
      }
      @media screen and (max-width: 850px) {
        padding: 0 0.2rem;
      }
      @media screen and (max-width: 650px) {
        padding: 0 0.1rem;
      }
      @media screen and (max-width: 550px) {
        padding: 0;
      }
    }
    .logo-img {
      filter: grayscale(0.5);
      height: 100px;
      width: 100px;

      margin: 0 1rem;
      padding: 0 0.5rem;
      @media screen and (max-width: 1100px) {
        margin-bottom: 1rem;
        height: 100px;
        width: 100px;
      }
      @media screen and (max-width: 850px) {
        font-size: 2.2rem;
        height: 80px;
        width: 80px;
      }
      @media screen and (max-width: 650px) {
        font-size: 1.8rem;
        height: 60px;
        width: 60px;
      }
      @media screen and (max-width: 520px) {
        font-size: 1.2rem;
        text-align: center;
      }
    }
  }
  .floating-img {
    .img1 {
      position: absolute;
      left: 0;
      top: 65%;
      height: 25%;
      @media screen and (max-width: 1200px) {
        left: -20px;
      }
      @media screen and (max-width: 1000px) {
        left: -40px;
      }
      @media screen and (max-width: 850px) {
        height: 18%;
        left: -10px;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
      img {
      }
    }
    .img2 {
      position: absolute;
      right: 0;
      top: 0%;
      height: 13%;
      @media screen and (max-width: 1200px) {
        right: -20px;
      }
      @media screen and (max-width: 1000px) {
        right: -40px;
      }
      @media screen and (max-width: 850px) {
        height: 18%;
        right: -10px;
      }
      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}
@keyframes zoomIn {
  0% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1.05);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
